import React, { useMemo, useState, useEffect, useCallback } from "react";
import "../styles/resourceblog.scss";
import axios from "axios";

import { graphql } from "gatsby";
import Layout from "../components/layoutnew";
import Heading from "../components/resource/blog/detail/Heading";
import BlogContent from "../components/resource/blog/detail/BlogContent";
import Blog from "../components/resource/blog/detail/Blog";

import SEO from "../components/seo";
import { Helmet } from "react-helmet";
import Faq from "../components/newServices/faq";
import { Guide } from "../components/resource/blog/detail/Guide";
import ServicesList from "../components/resource/blog/detail/ServicesList";
import ContactFormInBlog from "../components/resource/blog/detail/ContactFormInBlog";


const _randomslice = (ar, size) => {
  let new_ar = [...ar];
  new_ar.splice(Math.floor(Math.random() * ar.length), 1);
  return ar.length <= size + 1 ? new_ar : _randomslice(new_ar, size);
};

function ResourceBlogDetail({ data }) {
  const getContactData = (data) => {
    const values = data.allStrapiContact.edges[0].node;
    return values;
  };
  const replaceDoubleQuotesWithSingle = (input) => {
    return input.replace(/"/g, "'");
  };
  const contactGetData = useMemo(() => getContactData(data), [data]);
  const getData = data.allStrapiBlog.edges[0].node;
  const getLayoutData = data.allStrapiBlogPage.edges[0].node;
  const getIndexdata = data.allStrapiIndexpage.edges[0].node;

  const relatedBlogs = data.relatedBlogs.edges;
  const recentBlogs = data.recentBlogs.edges;
  const popularBlogs = data.popularBlogs.edges;
  const slugPrefix = "resource/blog/";

  const [isMobileView, setIsMobileView] = useState(false);
  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  const updateViewCount = useCallback(async () => {
    try {
      await axios.post(
        `${process.env.GATSBY_API_URL}/api/blogs/update_count/${getData.locale}/${getData.slug}`
      );
    } catch (error) {
      console.log("******************Error: ", error);
    }
  }, [getData]);

  useEffect(() => {
    checkMobileView();
    updateViewCount();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, [updateViewCount]);

  return (
    <Layout slug={`${slugPrefix}${getData.slug}`} locale={getData.locale} isMobileView={isMobileView}>
      <SEO
        noindexForce={false}
        description={getData.metaDes}
        slug={`${slugPrefix}${getData.slug}`}
        locale={getData.locale}
        title={`${getData.metaTitle}`}
        isBlogDetail={true}
        keywords={getData.metaKeywords}
      />
      <Helmet
        script={[
          {
            type: "application/ld+json",
            innerHTML: `
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${process.env.GATSBY_ROOT_URL}/resource/blog/${
              getData.slug
            }/"
              },
              "headline": "${getData.heading1}",
              "description": "${getData.metaDes}",
              "author": {
                "@type": "Person",
                "name": "${getData.Author || "Opsio Admin"}"
              },
              "publisher": {
                "@type": "Organization",
                "name": "Opsio",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${
                    process.env.GATSBY_ROOT_URL
                  }/static/signature-logo.png"
                }
              },
              "datePublished": "${getData.date} ${getData.time}",
              "image": "${getData?.blogimg?.[0]?.url}",
              "articleBody": "${replaceDoubleQuotesWithSingle(
                getData.detailDescription?.data?.detailDescription
              )}"
            }
            `,
          },
        ]}
      />
      <Heading data={getData} layoutData={getLayoutData} />
      <BlogContent
        data={{
          ...getData,
          testimonials: getIndexdata.testimonials,
        }}
        contactData={contactGetData}
        locale={getData.locale}
        content={getLayoutData.content}
        contactLayoutData={getLayoutData.blogContact}
        recentBlogs={recentBlogs}
        popularBlogs={popularBlogs}
        listTitles={getLayoutData.blogList.listTitles}
      />
      <Blog
        data={_randomslice(relatedBlogs, 3)}
        locale={getData.locale}
        slugPrefix={slugPrefix}
        isMobileView={isMobileView}
        blog={getLayoutData.blog}
      />
      <Guide unsure={getLayoutData.blog.unsure} />
      <ServicesList serviceData={getLayoutData.serviceList} />
      <ContactFormInBlog
        data={contactGetData}
        locale={getData.locale}
        layoutData={getLayoutData.blogContact}
      />
    </Layout>
  );
}

export default ResourceBlogDetail;

export const query = graphql`
  query ResourceBlogDetail($slug: String!, $locale: String!) {
    allStrapiContact(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          slug
          locale
          metaDes
          metaTitle
          btn
          contactDetail {
            contact
            sales
            support
            humanResource
            linkedIn
            emailId
            addressIn
            addressSe
            phoneNumberIn
            phoneNumberSe
          }
          contactLayout {
            email
            india
            sales
            sweden
            contact
            support
            information
            phoneNumber
            socialMedia
            humanResources
            officeLocation
            indiaPhoneNumber
            alertText
            alertTitle
            contactServices {
              src
              title
            }
            supportCards {
              btn
              src
              title
              description
            }
          }
          des
          file
          checkboxText1
          checkboxText2
          heading
          email
          infotitle1
          infotitle2
          message
          firstName
          lastName
          heroTheme {
            url
          }
          needhelp {
            strapi_json_value {
              answer
              question
            }
          }
          needhelpheading
          needhelptitle
          orgname
          phonenumber
          title
        }
      }
    }
    allStrapiBlog(filter: { slug: { eq: $slug }, locale: { eq: $locale } }) {
      edges {
        node {
          locale
          slug
          date
          time
          title
          metaDes
          metaKeywords
          metaTitle
          preview {
            data {
              preview
            }
          }
          id
          blogimg {
            ext
            url
          }
          technologyList {
            value
            id
          }
          serviceList {
            value
            id
          }
          industryList {
            value
            id
          }
          detailDescription {
            data {
              detailDescription
            }
          }
          conclusion {
            data {
              conclusion
            }
          }
          heading1
          Author {
            name
            designation
            postedIn
            about
          }
          faqTitle
          faqs {
            question
            answer {
              data {
                answer
              }
            }
          }
        }
      }
    }
    allStrapiBlogPage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          locale
          header {
            backBtn
          }
          content {
            search
            travel
            fintech
            allBlogs
            ecommerce
            elevateBtn
            learnHowTo
            testimonial
            allIndustries
            blogImgDescription
            bannerDescription
            defaultTestimonials {
              content
              image
              name
            }
          }
          blog {
            blogs
            relatedBlogs
            unsure {
              title
              description
              button
            }
          }
          blogContact {
            btn
            email
            hello
            tellUs
            fullName
            checkbox1
            checkbox2
            connectUs
            attachFile
            getInTouch
            selectedFile
            whatYouThink
            organizationName
          }
          blogList {
            listTitles {
              recent
              popular
            }
          }
          serviceList {
            disaster
            security
            enterprise
            description
            ourServices
            managedCloud
            cloudMigration
            cloudConsulting
            cloudOperations
            cloudOptimization
          }
        }
      }
    }
    allStrapiResourceMainSection(
      filter: { slug: { eq: "blog" }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          detailReadMore {
            cloudmigration {
              btn
              heading
              title
              slug
            }
          }
        }
      }
    }
    allStrapiIndexpage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          testimonials {
            name
            content {
              data {
                content
              }
            }
            imageUrl
          }
        }
      }
    }
    relatedBlogs: allStrapiBlog(
      filter: { slug: { ne: $slug }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          locale
          slug
          title
          date
          time
          heading1
          preview {
            data {
              preview
            }
          }
          id
          viewCount
          blogimg {
            ext
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 210) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    recentBlogs: allStrapiBlog(
      filter: { slug: { ne: $slug }, locale: { eq: $locale } }
      sort: { date: DESC }
      limit: 5
    ) {
      edges {
        node {
          locale
          slug
          title
          date
          time
          heading1
          preview {
            data {
              preview
            }
          }
          id
          viewCount
          blogimg {
            ext
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 210) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    popularBlogs: allStrapiBlog(
      filter: {
        slug: { ne: $slug }
        locale: { eq: $locale }
        viewCount: { ne: null }
      }
      sort: { viewCount: DESC }
      limit: 5
    ) {
      edges {
        node {
          locale
          slug
          title
          date
          time
          heading1
          preview {
            data {
              preview
            }
          }
          id
          viewCount
          blogimg {
            ext
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 210) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
